import { __decorate } from "tslib";
import { Component, Vue } from "@/types";
import "@/assets/scss/layouts/sectionLayout.scss";
import Header from "@/components/Header/Header.vue";
let SectionLayout = class SectionLayout extends Vue {
};
SectionLayout = __decorate([
    Component({
        components: { Header },
    })
], SectionLayout);
export default SectionLayout;
